import {Form, InputGroup} from "react-bootstrap";
import {useState} from "react";

function RowBlock({block, onPairChange, onPercentChange}) {
    const [localPair, setLocalPair] = useState(block.pair)

    const fetchPair = (url) => {
        return url.match(/([A-Z]*_[A-Z]*)/) ? url.match(/([A-Z]*_[A-Z]*)/)[0] : ''
    }

    const updatePair = (v) => {
        setLocalPair(fetchPair(v))
        onPairChange && onPairChange(fetchPair(v))
    }

    return (
        <>
            <td>
                <Form.Control type="text" value={localPair}
                              onBlur={(ev) => {
                                  updatePair(ev.target.value)
                              }}
                              onKeyUp={(ev) => {
                                  if (ev.key === 'Enter') {
                                      updatePair(ev.target.value)
                                  }
                              }}
                              onChange={(ev) => {
                                  setLocalPair(ev.target.value)
                              }} placeholder={'Пара'}
                />
            </td>
            <td style={{width: '150px'}}>
                <InputGroup>
                    <Form.Control type="number" value={block.percent} onChange={(ev) => {
                        onPercentChange && onPercentChange(ev.target.value)
                    }}/>
                    <InputGroup.Text>%</InputGroup.Text>
                </InputGroup>

            </td>
        </>
    )
}

export default RowBlock;