class Saver {
    #prefix;

    constructor(prefix) {
        this.#prefix = prefix;
    }

    save(key, value) {
        localStorage.setItem(this.#prefix + key, JSON.stringify(value));
    }

    load(key) {
        return JSON.parse(localStorage.getItem(this.#prefix + key));
    }
}

export default Saver;