import useWebSocket from "react-use-websocket";
import {useEffect, useState} from "react";
import {Button, Table} from "react-bootstrap";
import Row from "./Row";
import Saver from "./saver";
import BigNumber from "bignumber.js";

let rowPairs = [];
let subPairs = new Set([]);
let lastUpdateTime = 0;

function App() {
    const socketUrl = 'wss://api.gateio.ws/ws/v4/';

    const {
        sendJsonMessage,
        lastJsonMessage,
    } = useWebSocket(socketUrl, {
        onOpen: () => {
        },
        shouldReconnect: (closeEvent) => true,
    });

    const [orderBook, setOrderBook] = useState({});
    // const [lastUpdateTime, setLastUpdateTime] = useState(0);
    const [delay, setDelay] = useState(0);
    const [paused, setPaused] = useState(false);

    const updateRowPairs = (index, pairs) => {
        if (rowPairs[index] === undefined) {
            rowPairs[index] = new Set([])
        }

        pairs = pairs.filter(p => p !== '')
        rowPairs[index] = new Set([...pairs])
        updateSubscriptions()
    }

    const updateSubscriptions = (reset) => {
        reset = reset || false

        const newPairs = new Set([])
        rowPairs.forEach(p => {
            p.forEach(p => {
                newPairs.add(p)
            })
        })

        // Removed
        subPairs.forEach(p => {
            if (!newPairs.has(p) || reset) {
                unsubscribe(p)
                console.log('removed', p)
            }
        })

        // Added
        newPairs.forEach(p => {
            if (!subPairs.has(p) || reset) {
                subscribe(p)
                console.log('added', p)
            }
        })

        subPairs = newPairs
    }

    const unsubscribe = (pair) => {
        sendJsonMessage({
            time: Math.floor((new Date()).getTime() / 1000),
            channel: "spot.order_book",
            event: "unsubscribe",
            payload: [pair, "10", "1000ms"],
        });

        const newOrderBook = {...orderBook}
        delete newOrderBook[pair]
        setOrderBook(newOrderBook)
    }

    const subscribe = (pair) => {
        sendJsonMessage({
            time: Math.floor((new Date()).getTime() / 1000),
            channel: "spot.order_book",
            event: "subscribe",
            payload: [pair, "10", "1000ms"],
        });
    };

    const getDebuginfo = () => {
        return {
            subs: Object.keys(orderBook),
            delay: delay === 0 ? 0 : delay + 'ms'
        }
    }

    const pause = () => {
        setPaused(true)
    }
    const unpause = () => {
        setPaused(false)
    }

    const calcTotal = (o) => {
        return (new BigNumber(o[0])).multipliedBy(new BigNumber(o[1])).toFixed(6)
    }

    // Update order book when new message received
    useEffect(() => {
        if (lastJsonMessage === null || lastJsonMessage.result === null || lastJsonMessage.event !== "update") {
            return
        }

        if (paused) {
            return
        }

        const update = lastJsonMessage.result
        setOrderBook(ob => {
            let newOrderBook = {...ob}
            newOrderBook[update.s] = {
                asks: update.asks.reverse(),
                bids: update.bids,
            }
            return newOrderBook
        })

        if (lastUpdateTime !== 0) {
            setDelay((new Date()).getTime() - lastUpdateTime)
        }
        lastUpdateTime = (new Date()).getTime()
    }, [lastJsonMessage, paused]);

    return (
        <>
            <Table bordered>
                <thead>
                <tr>
                    <th>А</th>
                    <th>Б</th>
                    <th>В</th>
                    <th>Г</th>
                    <th>Д</th>
                    <th>Е</th>
                    <th>Ж</th>
                    <th>З</th>
                    <th>И</th>
                    <th>К</th>
                    <th>Л</th>
                    <th>М</th>
                    <th>Н</th>
                </tr>
                </thead>
                <tbody>
                {[...Array(20)].map((x, i) =>
                    <Row idx={i + 1} key={i} onPairsUpdate={p => updateRowPairs(i, p)} orderBook={orderBook}
                         saver={new Saver(`row_${i}_`)}/>
                )}
                </tbody>
            </Table>
            <hr/>
            <div>
                {paused ? <Button onClick={unpause}>Unpause</Button> : <Button onClick={pause}>Pause</Button>}
                <Button onClick={updateSubscriptions}>resub</Button>
            </div>
            <div style={{'display': 'flex', 'gap': '30px'}}>
                {Object.keys(orderBook).map((pair) =>
                    <div key={pair}>
                        <h5>{pair}</h5>
                        <strong>Asks</strong>
                        <Table bordered>
                            <thead>
                            <tr>
                                <th>Цена</th>
                                <th>Количество</th>
                                <th>Итог</th>
                            </tr>
                            </thead>
                            <tbody>
                            {orderBook[pair].asks.map((ask, i) =>
                                <tr key={i}>
                                    <td>{ask[0]}</td>
                                    <td>{ask[1]}</td>
                                    <td>{calcTotal(ask)}</td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                        <strong>Bids</strong>
                        <Table bordered>
                            <thead>
                            <tr>
                                <th>Цена</th>
                                <th>Количество</th>
                                <th>Итог</th>
                            </tr>
                            </thead>
                            <tbody>
                            {orderBook[pair].bids.map((bid, i) =>
                                <tr key={i}>
                                    <td>{bid[0]}</td>
                                    <td>{bid[1]}</td>
                                    <td>{calcTotal(bid)}</td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </div>
                )}
            </div>
            <div>
                Debug info <br/>
                {JSON.stringify(getDebuginfo())}
            </div>
        </>
    );
}

export default App;
