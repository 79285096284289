import BigNumber from "bignumber.js";

class Calculator {
    #orderLimit = 10

    constructor(orderLimit = 10) {
        this.#orderLimit = orderLimit

    }

    execute(blocks, startQty, orderBook, direction) {
        let subtotals = [
            new BigNumber(0),
            new BigNumber(0),
            new BigNumber(0),
            new BigNumber(0),
        ];

        let prevTotal = new BigNumber(0);

        if (direction === 'right') {
            subtotals[0] = this.buy(new BigNumber(startQty), blocks[0], orderBook);
            prevTotal = subtotals[0];

            subtotals[1] = this.sell(prevTotal, blocks[1], orderBook);
            prevTotal = subtotals[1];

            // Третий блок может быть не заполнен
            if (blocks[2].pair) {
                subtotals[2] = this.sell(prevTotal, blocks[2], orderBook);
                prevTotal = subtotals[2];
            }

            subtotals[3] = this.sell(prevTotal, blocks[3], orderBook);
        } else {
            subtotals[0] = this.buy(new BigNumber(startQty), blocks[0], orderBook);
            prevTotal = subtotals[0];

            subtotals[1] = this.buy(prevTotal, blocks[1], orderBook);
            prevTotal = subtotals[1];

            // Третий блок может быть не заполнен
            if (blocks[2].pair) {
                subtotals[2] = this.buy(prevTotal, blocks[2], orderBook);
                prevTotal = subtotals[2];
            }

            subtotals[3] = this.sell(prevTotal, blocks[3], orderBook);
        }

        return {
            subtotals: subtotals,
            total: subtotals[3]
        }
    }

    // Покупаем на все qty
    buy(qty, block, orderBook) {
        if (!block.pair || !orderBook[block.pair] || qty.isNaN()) {
            return new BigNumber(0)
        }

        const percent = asBigNumber(block.percent, 0)
        qty = qty.multipliedBy((100 - percent) / 100)

        const orders = orderBook[block.pair]['asks'];

        let left = qty;
        let result = new BigNumber(0);
        for (const order of orders) {
            const price = new BigNumber(order[0])
            const amount = new BigNumber(order[1])
            const cost = price.multipliedBy(amount)

            // Купили уже всё, что требуется
            if (left.minus(cost).isLessThanOrEqualTo(0)) {
                result = result.plus(left.dividedBy(price))
                left = new BigNumber(0)
                break
            }

            result = result.plus(amount)
            left = left.minus(cost)
        }

        if (left.isGreaterThan(0)) {
            return new BigNumber(-1)
        }

        return result
    }

    sell(qty, block, orderBook) {
        if (!block.pair || !orderBook[block.pair] || qty.isNaN() || qty < 0) {
            return new BigNumber(0)
        }

        const percent = asBigNumber(block.percent, 0)
        qty = qty.multipliedBy((100 - percent) / 100)

        const orders = orderBook[block.pair]['bids'];

        let left = qty;
        let result = new BigNumber(0);
        for (const order of orders) {
            const price = new BigNumber(order[0])
            const amount = new BigNumber(order[1])
            const cost = price.multipliedBy(amount)

            // Продали уже всё, что требуется
            if (left.minus(amount).isLessThanOrEqualTo(0)) {
                result = result.plus(left.multipliedBy(price))
                left = new BigNumber(0)
                break
            }

            result = result.plus(cost)
            left = left.minus(amount)
        }

        if (left.isGreaterThan(0)) {
            return new BigNumber(-1)
        }

        return result
    }
}

function asBigNumber(v, def) {
    const bn = new BigNumber(v)
    return bn.isNaN() ? new BigNumber(def) : bn
}

export default Calculator;