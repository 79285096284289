import {Button, Form} from "react-bootstrap";
import {useEffect, useState} from "react";
import RowBlock from "./RowBlock";
import Calculator from "./calculator";
// import Saver from "./saver";
import BigNumber from "bignumber.js";

const calc = new Calculator()
// let saver;

function Row({idx, onPairsUpdate, orderBook, saver}) {
    // if (saver === undefined) {
    //     const saver = new Saver('row' + idx + '_')
    // }

    const [direction, setDirection] = useState(saver.load('direction') || 'left')
    const [startQty, setStartQty] = useState(new BigNumber(saver.load('startQty') || 0))
    const [subtotals, setSubtotals] = useState([0, 0, 0, 0])
    const [total, setTotal] = useState(new BigNumber(0))

    const blocksDefault = [
        {pair: '', percent: 0},
        {pair: '', percent: 0},
        {pair: '', percent: 0},
        {pair: '', percent: 0},
    ]
    const [blocks, setBlocks] = useState(saver.load('blocks') || blocksDefault);

    const onPairChange = (index, value) => {
        const newBlocks = [...blocks]
        newBlocks[index].pair = value
        setBlocks(newBlocks)
        onPairsUpdate(blocks.map(b => b.pair))
    }

    const onPercentChange = (index, value) => {
        const newBlocks = [...blocks]
        newBlocks[index].percent = value
        setBlocks(newBlocks)
    }

    const formatTotal = (total, precision = 9) => {
        if (typeof total === 'number') {
            return total
        }

        if (total.isEqualTo(-1)) {
            return 'Недостаточно ликвидности'
        }

        if (total.isEqualTo(0)) {
            return '-'
        }

        return total.toFixed(precision)
    }

    useEffect(() => {
        const {subtotals, total} = calc.execute(blocks, startQty, orderBook, direction)
        setSubtotals(subtotals)
        setTotal(total)

        // Сохраняем в local storage
        saver.save('direction', direction)
        saver.save('startQty', startQty.toString())
        saver.save('blocks', blocks)
    }, [blocks, startQty, orderBook, direction, saver]);

    // Вызываем, чтобы подписаться на пары из local storage
    onPairsUpdate(blocks.map(b => b.pair))

    return (
        <>
            <tr>
                <td rowSpan={2}>{idx}</td>
                <td rowSpan={2}>
                    <Form.Check/>
                </td>
                <td rowSpan={2}>
                    {direction === 'left' ?
                        <Button onClick={() => setDirection('right')} variant="danger">←</Button> :
                        <Button onClick={() => setDirection('left')} variant="success">→</Button>
                    }
                </td>
                <td rowSpan={2}>
                    <Form.Control type="number" value={startQty} onChange={(ev) => {
                        setStartQty(ev.target.value)
                    }}/>
                </td>
                {[...Array(4)].map((x, i) =>
                    <RowBlock block={blocks[i]} key={i}
                              onPairChange={(v) => onPairChange(i, v)}
                              onPercentChange={(v) => onPercentChange(i, v)}
                    />
                )}
                <td rowSpan={2} style={{'textAlign': 'right'}}>
                    {total.isGreaterThan(startQty) ?
                        <span style={{'color': 'green'}}>{formatTotal(total, 3)}</span> :
                        formatTotal(total, 3)}
                </td>
            </tr>
            <tr>
                <td colSpan="2">
                    {formatTotal(subtotals[0])}
                </td>
                <td colSpan="2">
                    {formatTotal(subtotals[1])}
                </td>
                <td colSpan="2">
                    {formatTotal(subtotals[2])}
                </td>
                <td colSpan="2">
                    {formatTotal(subtotals[3])}
                </td>
            </tr>
        </>
    );
}

export default Row